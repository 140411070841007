import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, IconAmbientSmall, IconCheck, IconMinus, IconPharmacySmall, IconSnowflakeSmall, IconThermometerSmall, Paragraph, } from '@gordon/web-ui';
import './ScanListItem.scss';
const RenderBadges = ({ itemType, content, }) => {
    const isFrozen = itemType === 'frozen';
    const isAmbient = itemType === 'ambient';
    const isPharmacy = content === 'pharmacy';
    const isChilled = itemType === 'chilled';
    return (_jsxs(_Fragment, { children: [isFrozen && (_jsx(Badge, { variant: "brand", shape: "rectangle", size: "small", icon: IconSnowflakeSmall })), isPharmacy && (_jsx(Badge, { variant: "error", shape: "rectangle", size: "small", icon: IconPharmacySmall })), isChilled && (_jsx(Badge, { variant: "brand", shape: "rectangle", size: "small", icon: IconThermometerSmall })), isAmbient && (_jsx(Badge, { variant: "success", shape: "rectangle", size: "small", icon: IconAmbientSmall })), _jsx("span", { className: "scan-list-dot", children: "\u2022" })] }));
};
const ScanListItem = ({ item }) => {
    const { customerName, stopNumber, scanHistory, itemName, accountName, itemNumber, numberOfItems, itemType, content, } = item;
    const isPreloaded = scanHistory.some((scan) => scan.status === 'Pre-loaded');
    return (_jsxs("div", { className: "scan-list-item", children: [_jsxs("div", { className: "scan-list-item-header", children: [_jsx(Paragraph, { strong: true, children: `${stopNumber}. ${customerName}` }), isPreloaded ? _jsx(IconCheck, { color: "success" }) : _jsx(IconMinus, {})] }), _jsxs("div", { className: "scan-list-item-content", children: [_jsx(RenderBadges, { content: content, itemType: itemType }), " ", _jsx(Paragraph, { children: accountName })] }), _jsxs(Paragraph, { children: ["Item ", itemNumber, " of ", numberOfItems, " - ", itemName] })] }));
};
export default ScanListItem;
