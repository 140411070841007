import { constructApi } from '../../utils/createApi.js';
import { scan } from './items/scan.js';
import { getDepots } from './depots/getDepots.js';
import { getRoutes } from './dashboard/getRoutes.js';
import { getNearestDepot } from './depots/getNearestDepot.js';
import { registerBag } from './circular/registerBag.js';
import { updateBags } from './circular/updateBags.js';
import { validateBags } from './circular/validateBags.js';
import { getClients } from './circular/getClients.js';
import { getLaundries } from './circular/getLaundries.js';
import { scan as loadCarrierScan } from './loadCarrier/scan.js';
import { getInventory } from './loadCarrier/getInventory.js';
import { addItem } from './loadCarrier/addItem.js';
import { deleteItem } from './loadCarrier/deleteItem.js';
export const createWarehouseApi = constructApi({
    circular: {
        registerBag,
        updateBags,
        validateBags,
        getClients,
        getLaundries,
    },
    loadCarrier: {
        scan: loadCarrierScan,
        getInventory,
        addItem,
        deleteItem,
    },
    dashboard: {
        getRoutes,
    },
    depots: {
        getDepots,
        getNearestDepot,
    },
    items: {
        scan,
    },
});
