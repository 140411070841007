import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import './WhScannerApp.scss';
import PackageScanningPage from './pages/PackageScanning';
import RegisterCircularBagPage from './pages/RegisterCircularBag';
import CircularScanningPage from './pages/CircularScanning';
import useModal from './utils/useModal';
import { useLocation } from './stores/location';
import LocationDenied from './components/LocationDenied/LocationDenied';
import { useSetupDepotStore } from './stores/depot';
import { Dashboard } from './pages/Dashboard';
import LoadingSpinnerCentered from './components/LoadingSpinnerCentered/LoadingSpinnerCentered';
import { AuthProvider, useAuth } from './hooks/useAuth';
import { Login } from './pages/Login';
import { Navigate, Route, Routes, useLocation as useNavLocation } from 'react-router-dom';
export function App() {
    const { pathname, search } = useNavLocation();
    // Hack maybe we can get rid of this when they start using the correct URL
    if (search.includes('id') && pathname !== '/dashboard') {
        return _jsx(Navigate, { to: '/dashboard' + search, replace: true });
    }
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/dashboard", element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "*", element: _jsx(AuthProvider, { children: _jsx(WarehouseApp, {}) }) })] }));
}
function WarehouseApp() {
    const { token, isAuthenticated, isTokenExpired } = useAuth();
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    if (token && isTokenExpired())
        return _jsx(LoadingSpinnerCentered, {});
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "*", element: isAuthenticated ? _jsx(AuthenticatedApp, {}) : _jsx(Navigate, { to: "/login", replace: true }) })] }));
}
function AuthenticatedApp() {
    const [modal, showModal] = useModal();
    const { locationError } = useLocation();
    useSetupDepotStore();
    if (locationError)
        return _jsx(LocationDenied, {});
    return (_jsxs(_Fragment, { children: [_jsxs(Routes, { children: [_jsx(Route, { path: "/circular/register", element: _jsx(RegisterCircularBagPage, {}) }), _jsx(Route, { path: "/circular/scanner", element: _jsx(CircularScanningPage, { showModal: showModal }) }), _jsx(Route, { path: "/scanner", element: _jsx(PackageScanningPage, { showModal: showModal }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/scanner", replace: true }) })] }), modal] }));
}
