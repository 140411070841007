import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import './WhScannerApp.scss';
import PackageScanningPage from './pages/PackageScanning';
import RegisterCircularBagPage from './pages/RegisterCircularBag';
import { useNavigationStore } from './stores/navigation';
import CircularScanningPage from './pages/CircularScanning';
import useModal from './utils/useModal';
import { useLocation } from './stores/location';
import LocationDenied from './components/LocationDenied/LocationDenied';
import { useSetupDepotStore } from './stores/depot';
import { Dashboard } from './pages/Dashboard';
import LoadingSpinnerCentered from './components/LoadingSpinnerCentered/LoadingSpinnerCentered';
import { useAuth } from './hooks/useAuth';
import { Login } from './pages/Login';
export function App() {
    const { page } = useNavigationStore();
    const { navigate } = useNavigationStore();
    if (page.includes('id='))
        return _jsx(Dashboard, {});
    const token = localStorage.getItem('__wh-token__');
    const { isAuthenticated, isTokenExpired } = useAuth();
    if (token && isTokenExpired())
        return _jsx(LoadingSpinnerCentered, {});
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('login');
        }
    }, []);
    if (!isAuthenticated) {
        return _jsx(Login, {});
    }
    return _jsx(WhApp, {});
}
function WhApp() {
    const { page } = useNavigationStore();
    return page.includes('id=') ? _jsx(Dashboard, {}) : _jsx(WhScannerApp, {});
}
function WhScannerApp() {
    const { page, navigate } = useNavigationStore();
    const [modal, showModal] = useModal();
    const { locationError } = useLocation();
    useSetupDepotStore();
    useEffect(() => {
        if (page !== 'circular-scanning' && page !== 'circular-register') {
            navigate('package-scanning');
        }
    }, []);
    if (locationError)
        return _jsx(LocationDenied, {});
    return (_jsxs(_Fragment, { children: [page === 'circular-register' ? (_jsx(RegisterCircularBagPage, {})) : page === 'circular-scanning' ? (_jsx(CircularScanningPage, { showModal: showModal })) : (_jsx(PackageScanningPage, { showModal: showModal })), modal] }));
}
