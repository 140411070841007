import dayjs from 'dayjs';
export default function printLabel({ routeName, stopNumber, customerName, depotName, deliveryDate }, showModal) {
    function handleError(err) {
        console.log('cannot print', err);
        showModal('error', { message: 'Cannot print label' });
    }
    if (!BrowserPrint)
        return handleError('Cannot find BrowserPrint');
    BrowserPrint.getDefaultDevice('printer', function (device) {
        const selected_device = device;
        selected_device.send(`! 0 200 200 295 1
PW 575
ENCODING UTF-8
COUNTRY SWEDEN
TONE 0
SPEED 5
ON-FEED IGNORE
NO-PACE
GAP-SENSE

T180 4 0 550 285 ${normalizeString(customerName) || ''}
T180 4 1 550 235 ${normalizeString(routeName) || ''}
T180 4 1 550 160 Stop ${stopNumber || ''}
T180 4 0 550 80 ${normalizeString(depotName) || ''} 
T180 4 0 550 45 ${dayjs(deliveryDate).format('YYYY-MM-DD') || ''}
T180 7 0 250 25 ${dayjs().format('YYYY-MM-DD HH:mm') || ''}
FORM
PRINT
`, undefined, handleError);
    }, handleError);
}
function normalizeString(str) {
    // Normalize string - this will convert characters like 'é' to 'e', 'å' to 'a', etc.
    let normalizedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Cut the string if it's longer than 30 characters
    if (normalizedStr.length > 30) {
        normalizedStr = normalizedStr.substring(0, 30);
    }
    return normalizedStr;
}
