import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { App } from './WhScannerApp';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
if (process.env.ENVIRONMENT === 'PRODUCTION') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        // Performance Monitoring
        tracesSampleRate: 0, // Capture 0% of the performance data
    });
}
const app = document.getElementById('app');
if (!app)
    throw new Error('no root element');
const root = ReactDOM.createRoot(app);
root.render(_jsx(BrowserRouter, { children: _jsx(App, {}) }));
