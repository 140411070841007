import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import ActionsSection from '../components/ActionSection/ActionSection';
import { RFIDInput } from '../components/RfidInput';
import Section from '../components/Section/Section';
import ListBlock from '../components/ListBlock/ListBlock';
import { Button, IconAlertTriangle, IconCheckCircle, IconCircle, ListItem, Paragraph, } from '@gordon/web-ui';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import Pill, { PillItem } from '../components/Pill/Pill';
import './CircularScanning.scss';
import { useValidateBags } from '../requests/circular';
export default function CircularScanningPage({ showModal }) {
    const [successMessage, setSuccessMessage] = useState();
    const [tags, setTags] = useState([]);
    const { runRequest, data, setData } = useValidateBags();
    useEffect(() => {
        if (tags.length === 0) {
            return;
        }
        const timeout = setTimeout(() => {
            runRequest({ rfidCodes: tags });
        }, 1000);
        return () => clearTimeout(timeout);
    }, [tags]);
    const validatedBags = useMemo(() => tags.map((rfidCode) => {
        const matchedTag = data === null || data === void 0 ? void 0 : data.find((bag) => bag.rfidCode === rfidCode);
        return {
            rfidCode,
            _id: matchedTag === null || matchedTag === void 0 ? void 0 : matchedTag._id,
            exists: !!matchedTag,
            qrCode: matchedTag === null || matchedTag === void 0 ? void 0 : matchedTag.qrCode,
        };
    }), [data]);
    const bags = tags.map((tag) => {
        var _a;
        const bag = validatedBags.find((bag) => bag.rfidCode === tag);
        return {
            rfidCode: tag,
            _id: bag === null || bag === void 0 ? void 0 : bag._id,
            exists: (_a = bag === null || bag === void 0 ? void 0 : bag.exists) !== null && _a !== void 0 ? _a : true,
            qrCode: bag === null || bag === void 0 ? void 0 : bag.qrCode,
        };
    });
    const confirmedBags = bags.filter((bag) => bag._id);
    const unknownBags = bags.filter((bag) => !bag.exists);
    return (_jsxs(Layout, { fullHeight: true, children: [_jsx(ActionsSection, { showModal: showModal }), _jsx(RFIDInput, { onScanResult: ({ scanCode }) => {
                    setTags((prev) => [...new Set([...prev, scanCode])]);
                }, enabled: !successMessage, prefix: "300ED89F335" }), _jsxs(Section, { className: "rfid-section", children: [_jsx(SectionHeader, { heading: successMessage ? `${confirmedBags.length} bags ${successMessage}` : 'Scan RFID' }), _jsxs(Pill, { children: [_jsx(PillItem, { label: "Scanned tags", value: `${tags.length}` }), _jsx(PillItem, { label: "Unknown", value: `${unknownBags.length || '-'}` })] }), _jsx("div", { className: "list-wrapper", children: _jsx(ListBlock, { children: _jsx("div", { className: "list-container", children: bags.length > 0 ? (bags.map(({ rfidCode, exists, qrCode }) => (_jsx(ListItem, { heading: rfidCode, children: _jsx("div", { className: "list-item-content", children: exists ? (qrCode ? (_jsxs(_Fragment, { children: [_jsx(IconCheckCircle, { color: "success" }), _jsxs(Paragraph, { children: ["QR ", qrCode] })] })) : (_jsxs(_Fragment, { children: [_jsx(IconCircle, { color: "subtle" }), _jsx(Paragraph, { children: "Pending" })] }))) : (_jsxs(_Fragment, { children: [_jsx(IconAlertTriangle, { color: "warning" }), _jsx(Paragraph, { children: "Not a registered bag" })] })) }) }, rfidCode)))) : (_jsx(Paragraph, { children: "Scanned bags will be listed here." })) }) }) }), confirmedBags.length > 0 && (_jsx("div", { className: "footer-actions", children: successMessage ? (_jsx(Button, { onClick: () => {
                                setSuccessMessage(undefined);
                                setTags([]);
                                setData(() => undefined);
                            }, variant: "primary", text: "New scan" })) : (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => {
                                        setData(() => undefined);
                                        setTags([]);
                                    }, variant: "secondary", text: "Clear" }), _jsx(Button, { onClick: () => {
                                        showModal('bulkUpdateCircularBags', {
                                            onSuccess: (message) => setSuccessMessage(message),
                                            ids: confirmedBags.map((bag) => bag._id),
                                        });
                                    }, variant: "primary", text: "Done" })] })) }))] })] }));
}
