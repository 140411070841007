var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createWarehouseApi } from '@gordon/common/apis/warehouse';
import { parseError } from '@gordon/common/utils/parseError';
import { getToken, isTokenExpired, refreshTokenRequest } from '../hooks/useAuth';
function client(url, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const _a = options !== null && options !== void 0 ? options : { headers: {} }, { requiresAuth = true, body } = _a, fetchOptions = __rest(_a, ["requiresAuth", "body"]);
        if (requiresAuth && isTokenExpired()) {
            yield refreshTokenRequest();
        }
        const token = getToken();
        if (!token && requiresAuth) {
            window.location.reload();
            throw new Error('User not logged in');
        }
        return fetch(`${process.env.BACKEND_ENDPOINT}/${url}`, Object.assign(Object.assign({}, fetchOptions), { body: body, credentials: requiresAuth ? 'include' : undefined, headers: Object.assign(Object.assign(Object.assign({}, (requiresAuth ? { Authorization: `Bearer ${token}` } : undefined)), { 'content-type': 'application/json' }), fetchOptions.headers) }));
    });
}
function handleResponse(response) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        if (!response.ok) {
            if ((_a = response.headers.get('content-type')) === null || _a === void 0 ? void 0 : _a.includes('application/json')) {
                const data = yield response.json();
                if (data.status === 'error') {
                    throw new Error(parseError(data));
                }
            }
            throw new Error(response.statusText);
        }
        if (((_b = response.headers.get('content-type')) === null || _b === void 0 ? void 0 : _b.includes('application/pdf ')) ||
            ((_c = response.headers.get('content-type')) === null || _c === void 0 ? void 0 : _c.includes('text/csv'))) {
            const blob = yield response.blob();
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
            return {};
        }
        const data = yield response.json();
        if (data.status === 'UNAUTHORIZED') {
            window.location.reload();
            throw new Error('Unauthorized');
        }
        if (data.status !== 'OK') {
            throw new Error(data.message);
        }
        return data.payload;
    });
}
export const api = createWarehouseApi(client, handleResponse);
