export function getStatusColor(statusCode) {
    if ((statusCode > 90 && statusCode < 110) || statusCode === 130)
        return 'subtle';
    if (statusCode === 110)
        return 'warning';
    if (statusCode > 90)
        return 'success';
    return 'strong';
}
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function capitalizeFirstLetterInEachSentence(str) {
    return str
        .split(' ')
        .map((sentence) => capitalizeFirstLetter(sentence))
        .join(' ');
}
export function getPreloadProgress(scannedItem) {
    var _a, _b, _c;
    const totalItems = (_b = (_a = scannedItem.preloadItems) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
    const scanProgress = (_c = scannedItem.preloadItems) === null || _c === void 0 ? void 0 : _c.reduce((acc, item) => {
        const hasBeenPreLoaded = item.scanHistory.some((history) => history.statusCode === 55);
        if (hasBeenPreLoaded)
            return acc + 1;
        return acc;
    }, 0);
    return { totalItems, scanProgress };
}
