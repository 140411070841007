import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Alert, Button, Heading, Input, Paragraph } from '@gordon/web-ui';
import Section from '../components/Section/Section';
import { useAuth } from '../hooks/useAuth';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
export const Login = () => {
    const { isAuthenticated, login, isLoading, loginFailed } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/scanner');
        }
    }, [isAuthenticated]);
    const handleSubmit = (e) => {
        e.preventDefault();
        login({ email, password });
    };
    return (_jsx("div", { className: "authentication-layout", children: _jsx("div", { className: "authentication-layout__content", children: _jsxs(Section, { className: "authentication-layout__content__wrapper", children: [_jsx("div", { className: "authentication-layout__logo", children: _jsx("img", { src: "/img/gordon-logo.svg", alt: ".Gordon" }) }), _jsxs(Section, { className: "authentication-layout__heading", children: [_jsx(Heading, { size: "h1", children: "Warehouse login" }), _jsxs(Paragraph, { size: "large", children: ["Welcome to the Gordon Warehouse app.", _jsx("br", {}), " Please enter your details to sign in."] }), _jsx(Paragraph, { size: "large" })] }), _jsx("form", { onSubmit: handleSubmit, children: _jsxs(Section, { className: "authentication-layout__form", children: [_jsx(Input, { label: "Email", type: "email", value: email, onChange: (e) => setEmail(e.target.value) }), _jsx(Input, { label: "Password", type: "password", value: password, onChange: (e) => setPassword(e.target.value) }), _jsx(Button, { isLoading: isLoading, type: "submit", variant: "primary", text: "Sign in", disabled: email === '' || password === '' }), loginFailed && (_jsx("div", { children: _jsx(Alert, { variant: "error", heading: "Incorrect email or password" }) })), _jsx(Paragraph, { color: "default", children: _jsx("a", { href: `${process.env.LASTMILE_ENDPOINT}/request-password`, children: "Forgot password?" }) })] }) })] }) }) }));
};
