import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Select, RadioButton, InputLabels, Modal, Button, ModalSection } from '@gordon/web-ui';
import { useNavigationStore } from '../../../stores/navigation';
import { useDepots } from '../../../stores/depot';
export default function ChangeDepotModal({ onClose, isDestination, }) {
    const { navigate, page } = useNavigationStore();
    const { depots, depot, setDepot, setDestinationDepot } = useDepots();
    const [selectedPage, setSelectedPage] = useState(page);
    const [selectedDepotId, setSelectedDepotId] = useState(depot === null || depot === void 0 ? void 0 : depot._id);
    const selectedDepot = useMemo(() => depots.find(({ _id }) => _id === selectedDepotId), [selectedDepotId]);
    const options = depots.map((depot) => ({ text: depot.name, value: depot._id }));
    return (_jsx(Modal, { title: isDestination ? 'Change destination depot' : 'Change depot', supportingText: isDestination
            ? 'Select the end destination that the item/pallet will be sent to.'
            : 'Select the current depot that you are working from.', size: "small", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", text: "Confirm", onClick: () => {
                        if (!selectedDepot)
                            return;
                        if (isDestination) {
                            setDestinationDepot(selectedDepot);
                        }
                        else {
                            setDepot(selectedDepot);
                            navigate(selectedDepot.circularEnabled ? selectedPage : 'package-scanning');
                        }
                        onClose();
                    } })] }), children: _jsxs(ModalSection, { children: [_jsx(Select, { value: selectedDepotId, allowUnselect: false, options: options, withSearch: true, onSelect: setSelectedDepotId }), !isDestination && (selectedDepot === null || selectedDepot === void 0 ? void 0 : selectedDepot.circularEnabled) && (_jsxs(InputLabels, { label: "Scanning for:", children: [_jsx(RadioButton, { label: "Labels (QR or barcodes)", checked: selectedPage !== 'circular-scanning', onChange: () => setSelectedPage('package-scanning') }), _jsx(RadioButton, { label: "Labels Circular bags (RFID tags)", checked: selectedPage === 'circular-scanning', onChange: () => setSelectedPage('circular-scanning') })] }))] }) }));
}
