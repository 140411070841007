import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, ListItem, Modal, ModalRow, ModalSection, Paragraph } from '@gordon/web-ui';
import { getPreloadProgress } from '../../../utils/helperFunctions';
export default function PreloadDoneModal({ onClose = () => { }, onConfirm = () => { }, scannedItem, }) {
    const { totalItems, scanProgress } = getPreloadProgress(scannedItem);
    return (_jsx(Modal, { title: "Pre-load done", size: "small", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", text: "Confirm", onClick: () => {
                        onConfirm();
                        onClose();
                    } })] }), children: _jsxs(ModalSection, { children: [scanProgress !== totalItems && (_jsx(ModalRow, { children: _jsx(Alert, { variant: "primary", heading: "Incomplete Pre-load", supportingText: "Not all items have been pre-loaded. Do you want to proceed anyway?" }) })), _jsx(ModalRow, { children: _jsx(ListItem, { heading: "Route name", children: _jsx(Paragraph, { children: scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.routeName }) }) }), _jsx(ModalRow, { children: _jsx(ListItem, { heading: "Items", children: _jsx(Paragraph, { children: `${scanProgress}/${totalItems} items have been pre-loaded` }) }) })] }) }));
}
