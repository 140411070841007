export function getStatusColor(statusCode) {
    if ((statusCode > 90 && statusCode < 110) || statusCode === 130)
        return 'subtle';
    if (statusCode === 110)
        return 'warning';
    if (statusCode > 90)
        return 'success';
    return 'strong';
}
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function capitalizeFirstLetterInEachSentence(str) {
    return str
        .split(' ')
        .map((sentence) => capitalizeFirstLetter(sentence))
        .join(' ');
}
