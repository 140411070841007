import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import useZxingScanner from './useZxingScanner';
import './VideoSection.scss';
export default function VideoSection({ onScanResult }) {
    const [flash, setFlash] = useState(false);
    const setCode = (code) => {
        if (!code)
            return;
        onScanResult({ scanCode: code, tag: 'camera' });
        setFlash(true);
        setTimeout(() => setFlash(false), 250);
    };
    useZxingScanner(setCode);
    return (_jsx("div", { id: "wh-app-video-container", className: `wh-app-video-section ${flash ? 'wh-app-video-section--scanned' : ''}`, children: _jsx("video", { autoPlay: true, id: "wh-app-video", className: "wh-app-video-section__video-player" }) }));
}
