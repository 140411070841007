import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import VideoSection from '../components/VideoSection/VideoSection';
import useExternalScanner from '../utils/useExternalScanner';
import { Layout } from '../components/Layout/Layout';
import { LoadCarrierSection } from '../components/LoadCarrierSection/LoadCarrierSection';
import { failAudio, successAudio } from '../utils/useActions';
import { useDepots } from '../stores/depot';
import { useCoordinates } from '../stores/location';
import { usePromise } from '@gordon/frontend/usePromise';
import { api } from '../requests/api';
export default function LoadCarrierScanner({ showVideo, showModal, }) {
    var _a;
    const { lat, lng } = useCoordinates();
    const { depot } = useDepots();
    const [scanCode, setScanCode] = React.useState(undefined);
    const { run: runCarrierRequest, data: carrierData, isLoading: carrierIsLoading, error: carrierError, setData: setCarrierData, } = usePromise(api.loadCarrier.scan);
    const { run: runCarrierInventoryRequest, data: carrierInventoryData, isLoading: carrierInventoryIsLoading, error: carrierInventoryError, setData: setCarrierInventoryData, } = usePromise(api.loadCarrier.getInventory);
    const { run: runAddItem, data: addItemData, error: addItemError, setData: setAddItemData, } = usePromise(api.loadCarrier.addItem);
    const { run: runDeleteItem, isSuccess } = usePromise(api.loadCarrier.deleteItem);
    const [itemToBeDeleted, setItemToBeDeleted] = React.useState(undefined);
    const [itemInventory, setItemInventory] = React.useState(carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.inventory);
    const eject = () => {
        setCarrierInventoryData(() => undefined);
        setAddItemData(() => undefined);
        setCarrierData(() => undefined);
        setScanCode(undefined);
        setItemInventory(undefined);
        setItemToBeDeleted(undefined);
    };
    useEffect(() => {
        if (carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.inventory) {
            setItemInventory(carrierInventoryData.inventory);
        }
    }, [carrierInventoryData]);
    useEffect(() => {
        if (isSuccess) {
            setItemInventory((prev) => {
                if (prev) {
                    return prev.filter((item) => item._id !== itemToBeDeleted);
                }
                return prev;
            });
            setItemToBeDeleted(undefined);
        }
    }, [isSuccess]);
    useEffect(() => {
        if ((carrierData === null || carrierData === void 0 ? void 0 : carrierData.scanCode) && itemToBeDeleted) {
            runDeleteItem(carrierData.scanCode, itemToBeDeleted);
            setScanCode(undefined);
        }
    }, [itemToBeDeleted]);
    useEffect(() => {
        if (addItemData) {
            const { name, weight, account, _id } = addItemData;
            const item = { name, weight, accountName: account.name, _id };
            setItemInventory((prev) => {
                if (prev) {
                    const isDuplicate = prev.some((existingItem) => existingItem._id === item._id);
                    if (isDuplicate)
                        return prev;
                    return [...prev, item];
                }
                return [item];
            });
        }
    }, [addItemData]);
    const addItem = () => {
        var _a, _b;
        if (!(carrierData === null || carrierData === void 0 ? void 0 : carrierData.scanCode) ||
            !((_a = carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.route) === null || _a === void 0 ? void 0 : _a._id) ||
            !scanCode ||
            !lat ||
            !lng ||
            !(depot === null || depot === void 0 ? void 0 : depot._id))
            return;
        runAddItem(carrierData.scanCode, {
            routeId: (_b = carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.route) === null || _b === void 0 ? void 0 : _b._id,
            itemScanCode: scanCode,
            depotId: depot._id,
            lat,
            lng,
        });
        setScanCode(undefined);
    };
    const sendScanRequest = (scan) => {
        successAudio.play();
        setScanCode(scan.scanCode);
    };
    useEffect(() => {
        var _a;
        if (!scanCode)
            return;
        if (!carrierData && !carrierIsLoading) {
            // first get loadcarrier
            runCarrierRequest(scanCode);
            return;
        }
        if (!carrierInventoryData && !carrierInventoryIsLoading) {
            // then get inventory and  route info by item scan-code
            runCarrierInventoryRequest((_a = carrierData === null || carrierData === void 0 ? void 0 : carrierData.scanCode) !== null && _a !== void 0 ? _a : '', scanCode);
            return;
        }
        addItem();
    }, [scanCode]);
    useEffect(() => {
        var _a, _b;
        if (carrierInventoryData) {
            showModal('loadLoadCarrierModal', {
                loadCarrierId: carrierData === null || carrierData === void 0 ? void 0 : carrierData.scanCode,
                model: carrierData === null || carrierData === void 0 ? void 0 : carrierData.model,
                routeName: (_a = carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.route) === null || _a === void 0 ? void 0 : _a.name,
                itemsScanned: (_b = carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.inventory) === null || _b === void 0 ? void 0 : _b.length.toString(),
                onCancel: eject,
                onConfirm: addItem,
            });
        }
    }, [carrierInventoryData]);
    useEffect(() => {
        if (carrierError) {
            failAudio.play();
            showModal('error', { message: carrierError.message });
        }
        else if (carrierInventoryError) {
            failAudio.play();
            showModal('error', { message: carrierInventoryError.message });
        }
        else if (addItemError) {
            failAudio.play();
            showModal('error', { message: addItemError.message });
        }
    }, [carrierError, carrierInventoryError, addItemError]);
    const createCarrierTitle = () => {
        if (!(carrierData === null || carrierData === void 0 ? void 0 : carrierData._id))
            return 'Scan QR-code to create load carrier';
        if (!carrierInventoryData)
            return 'Scan item belonging to route';
        return 'Loading carrier';
    };
    useExternalScanner(sendScanRequest);
    return (_jsxs(Layout, { showVideo: showVideo, children: [showVideo && _jsx(VideoSection, { onScanResult: sendScanRequest }), _jsx(LoadCarrierSection, { carrierData: carrierData, addItemResponse: addItemData, itemInventory: itemInventory, deleteItem: setItemToBeDeleted, deliveryDate: carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.deliveryDate, routeName: (_a = carrierInventoryData === null || carrierInventoryData === void 0 ? void 0 : carrierInventoryData.route) === null || _a === void 0 ? void 0 : _a.name, eject: eject, carrierTitle: createCarrierTitle(), showModal: showModal })] }));
}
