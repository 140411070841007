import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
const useLocationStore = create()((set) => ({
    lat: undefined,
    lng: undefined,
    locationError: undefined,
    onError: (error) => set({ locationError: error }),
    onUpdate: (position) => {
        const coords = position.coords;
        if (!(coords === null || coords === void 0 ? void 0 : coords.latitude) || !(coords === null || coords === void 0 ? void 0 : coords.longitude))
            return;
        set({ locationError: undefined, lat: coords.latitude, lng: coords.longitude });
    },
}));
export const useCoordinates = () => useLocationStore(useShallow(({ lat, lng }) => ({ lat, lng })));
export function useLocation() {
    const { onError, onUpdate, locationError } = useLocationStore(useShallow(({ locationError, onError, onUpdate }) => ({ locationError, onError, onUpdate })));
    useEffect(() => {
        if (locationError) {
            captureException(`Location error from browser, error: ${locationError}`);
        }
    }, [locationError]);
    const watchLocationOptions = {
        maximumAge: 300000, // max time of a possible cached position that is acceptable to return
        timeout: 10000,
        enableHighAccuracy: false,
    };
    function watchPosition() {
        var _a;
        const watchPositionId = (_a = navigator.geolocation) === null || _a === void 0 ? void 0 : _a.watchPosition(onUpdate, onError, watchLocationOptions);
        return watchPositionId;
    }
    function clearPositionWatch(watchPositionId) {
        var _a;
        (_a = navigator.geolocation) === null || _a === void 0 ? void 0 : _a.clearWatch(watchPositionId);
    }
    useEffect(() => {
        const watchPositionId = watchPosition();
        return () => clearPositionWatch(watchPositionId);
    }, []);
    return { locationError };
}
