import { useEffect, useState } from 'react';
import { usePromise } from '@gordon/frontend/usePromise';
import { useCoordinates } from '../stores/location';
import { useDepots } from '../stores/depot';
import { api } from '../requests/api';
export default function useScanner({ state, onSuccessfulScan, onScanError, }) {
    const [scan, setScan] = useState(undefined);
    const { mode, undoNextScan, routeId, printLabelOnScan } = state;
    const isDestinationDepotEnabled = ['arrive', 'sort', 'interhub'].includes(mode);
    const { lat, lng } = useCoordinates();
    const { depot, destinationDepot } = useDepots();
    const { run, data, error, isLoading } = usePromise(api.items.scan);
    useEffect(() => {
        if (scan) {
            // prevent double scan since undoNextScan is set to false when scanned
            const id = setTimeout(() => setScan(undefined), 1000);
            return () => clearTimeout(id);
        }
    }, [depot, destinationDepot, mode, undoNextScan, printLabelOnScan, scan]);
    useEffect(() => {
        var _a;
        if (!scan || !lat || !lng)
            return;
        if (!depot) {
            return onScanError('No depot selected');
        }
        run(scan.scanCode, scan.tag, printLabelOnScan.toString(), {
            depotId: depot._id,
            destinationDepotId: isDestinationDepotEnabled ? destinationDepot === null || destinationDepot === void 0 ? void 0 : destinationDepot._id : undefined,
            routeId: (_a = scan === null || scan === void 0 ? void 0 : scan.routeId) !== null && _a !== void 0 ? _a : routeId,
            mode: mode,
            undo: undoNextScan,
            lat: lat,
            lng: lng,
        });
    }, [scan === null || scan === void 0 ? void 0 : scan.scanCode]);
    function runScanRequest(params) {
        if (!params.scanCode)
            return onScanError('Missing scanCode');
        setScan(params);
    }
    useEffect(() => {
        if (data && scan) {
            onSuccessfulScan({ scannedItem: data, params: scan }, runScanRequest);
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            const timeoutId = setTimeout(() => setScan(undefined), 1000);
            onScanError(error.message);
            return () => clearTimeout(timeoutId);
        }
    }, [error]);
    return {
        scanCode: scan === null || scan === void 0 ? void 0 : scan.scanCode,
        isLoading,
        sendScanRequest: runScanRequest,
        isDestinationDepotEnabled,
    };
}
