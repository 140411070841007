import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import React, { useState, useEffect, useRef } from 'react';
import '../pages/Dashboard.scss';
import { Badge, IconMedicalCross, IconSnowflake } from '@gordon/web-ui';
const formatTime = (time) => dayjs(time).format('HH:mm');
const getStatusMessage = (route) => {
    const now = dayjs();
    const loadEndTime = dayjs(route.promisedEnd);
    const loadStartTime = dayjs(route.promisedStart);
    if (!route.status)
        return;
    if (route.status === 'LOADING') {
        const remainingTime = loadEndTime.diff(now, 'minute');
        if (remainingTime <= 0) {
            return `[DELAY ${Math.abs(remainingTime)} MIN]`;
        }
    }
    else if (now.isAfter(loadStartTime) &&
        !['COMPLETED', 'ARRIVED', 'LOADING'].includes(route.status)) {
        const lateMinutes = now.diff(loadStartTime, 'minute');
        return `[${lateMinutes} MIN]`;
    }
    return '';
};
const DashboardTableRow = ({ route }) => {
    var _a;
    const hasRequirement = route.isPharmacy || route.isFrozen;
    const [statusColor, setStatusColor] = useState('white');
    const nameRef = useRef(null);
    const nameWrapperRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    useEffect(() => {
        if (route.status === 'COMPLETED') {
            return setStatusColor('#0FEC89');
        }
        if (!route.status) {
            return setStatusColor('white');
        }
        else if ((dayjs().isAfter(dayjs(route.promisedStart)) &&
            !['COMPLETED', 'ARRIVED', 'LOADING'].includes(route.status)) ||
            (route.status === 'LOADING' && dayjs().isAfter(dayjs(route.promisedEnd))) ||
            (route.status === 'LOADING' && dayjs().isAfter(dayjs(route.promisedEnd)))) {
            return setStatusColor('#FF5050');
        }
        else {
            return setStatusColor('white');
        }
    }, [route.status, route.promisedStart]);
    useEffect(() => {
        const nameElement = nameRef.current;
        const nameWrapperElement = nameWrapperRef.current;
        if (nameElement && nameWrapperElement) {
            setIsScrolling(nameElement.clientWidth > nameWrapperElement.clientWidth);
        }
    }, [route.name]);
    const actualStartTime = route.actualStart ? dayjs(route.actualStart) : undefined;
    const actualCompletedTime = route.actualEnd ? dayjs(route.actualEnd) : undefined;
    const statusMessage = getStatusMessage(route);
    return (_jsxs("tr", { className: "table-row", children: [_jsxs("td", { align: "left", children: [formatTime(route.promisedStart), actualStartTime && `[${actualStartTime.format('HH:mm')}]`] }), _jsxs("td", { align: "left", children: [formatTime(route.promisedEnd), actualCompletedTime && ` [${actualCompletedTime.format('HH:mm')}]`] }), _jsxs("td", { className: "route", align: "left", children: [_jsx("div", { style: hasRequirement ? { maxWidth: 1250 } : { maxWidth: 1450 }, className: "name-wrapper", ref: nameWrapperRef, children: _jsx("span", { "data-content": route.name, className: `${isScrolling ? 'name scrolling' : 'name'}`, ref: nameRef, children: route.name }) }), hasRequirement ? (_jsxs("span", { className: "requirements", children: [route.isPharmacy && (_jsx(Badge, { type: "secondary", variant: "error", icon: IconMedicalCross, shape: "rectangle" })), route.isFrozen && (_jsx(Badge, { type: "primary", variant: "brand", icon: IconSnowflake, shape: "rectangle" }))] })) : null] }), _jsx("td", { align: "center", children: (_a = route.gate) !== null && _a !== void 0 ? _a : '-' }), _jsx("td", { align: "left", style: { color: statusColor }, children: !route.status ? '-' : `${route.status} ${statusMessage}` })] }));
};
export default React.memo(DashboardTableRow);
