import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MeatballMenu, MenuListItem, Paragraph, useDeviceWidth } from '@gordon/web-ui';
import Section from '../Section/Section';
import SectionHeader from '../SectionHeader/SectionHeader';
import './ActionSection.scss';
import { useDepots } from '../../stores/depot';
import { useAuth } from '../../hooks/useAuth';
export default function ActionsSection({ showModal, children, }) {
    var _a;
    const { depot } = useDepots();
    const { logout } = useAuth();
    const { isTablet } = useDeviceWidth();
    return (_jsxs(Section, { className: "action-section", children: [_jsx(SectionHeader, { heading: (_a = depot === null || depot === void 0 ? void 0 : depot.name) !== null && _a !== void 0 ? _a : '', isLoading: !depot, children: _jsxs("div", { className: "action-section__menu", children: [_jsx("div", { onClick: () => {
                                showModal('changeDepot', {});
                            }, children: _jsx(Paragraph, { color: "strong", children: _jsx("span", { style: { textDecorationLine: 'underline', textUnderlineOffset: '3px' }, children: (depot === null || depot === void 0 ? void 0 : depot.circularEnabled) ? 'Edit' : 'Change depot' }) }) }), _jsxs(MeatballMenu, { variant: "tertiary", position: isTablet ? 'right' : undefined, children: [_jsx(MenuListItem, { text: "Update password", value: "updatePassword", onClick: () => window.open(`${process.env.LASTMILE_ENDPOINT}/update-password`, '_self') }), _jsx(MenuListItem, { text: "Log out", value: "logout", onClick: () => {
                                        logout();
                                    } })] })] }) }), children] }));
}
