import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import Section from '../Section/Section';
import Pill, { PillItem } from '../Pill/Pill';
import ListBlock, { ListBlockItem } from '../ListBlock/ListBlock';
import './PalletDetailsSection.scss';
import { capitalizeFirstLetter, capitalizeFirstLetterInEachSentence, getStatusColor, } from '../../utils/helperFunctions';
import TimeLine from '../ItemDetailsSection/TimeLine';
export default function PalletDetailsSection({ scannedPallet }) {
    var _a, _b, _c;
    return (_jsx("div", { className: "wh-app-item-details", children: _jsxs(Section, { className: "wh-app-item-details__section", children: [_jsxs(Pill, { children: [_jsx(PillItem, { label: "Status", value: `${scannedPallet.status} (${capitalizeFirstLetter(scannedPallet.scanType)})`, color: scannedPallet.status ? 'brand-primary' : undefined }), _jsx(PillItem, { label: "Delivery Date", value: !((_a = scannedPallet.deliveryDates) === null || _a === void 0 ? void 0 : _a.length)
                                ? '-'
                                : scannedPallet.deliveryDates.length === 1
                                    ? dayjs(scannedPallet.deliveryDates[0]).format('ddd, DD MMM')
                                    : 'Multiple dates' })] }), _jsxs(ListBlock, { children: [_jsx(ListBlockItem, { label: "End depot", value: capitalizeFirstLetterInEachSentence((_b = scannedPallet.depotName) !== null && _b !== void 0 ? _b : '-') }), _jsx(ListBlockItem, { label: "Number of items", value: ((_c = scannedPallet.numberOfItems) === null || _c === void 0 ? void 0 : _c.toString()) || '-' }), _jsx(ListBlockItem, { label: "Accounts", value: scannedPallet.accounts.map((account) => account.name).join(', ') || '-', color: scannedPallet.statusCode ? getStatusColor(scannedPallet.statusCode) : undefined }), _jsx(ListBlockItem, { label: 'Routes', values: scannedPallet.routes
                                ? scannedPallet.routes
                                    .sort((a, b) => b.numberOfItems - a.numberOfItems)
                                    .map((route) => `${capitalizeFirstLetter(route.name)} (${route.numberOfItems})`)
                                : ['-'] })] }), scannedPallet.scanHistory && (_jsx(ListBlock, { children: _jsx(TimeLine, { scanHistory: scannedPallet.scanHistory, scannedItem: scannedPallet }) }))] }) }));
}
