import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem } from '../ListItem';
import { TimelineCircle } from './TimelineCircle';
import { stateColorMap } from './shared';
import { classNames } from '../utils/helperFunctions';
import { Paragraph } from '../Typography';
import { Button } from '../Button';
import styles from './TimelineStep.module.scss';
const ProgressStep = ({ state, isLastStep, prevState, nextState, }) => {
    return (_jsxs("div", { className: styles['timeline-step__progress'], children: [_jsx("div", { className: classNames(styles['timeline-step__progress__top-line'], prevState &&
                    styles[stateColorMap[!['default', 'dashed'].includes(state) ? 'done' : 'default']]) }), _jsx(TimelineCircle, { state: state }), !isLastStep && (_jsx("div", { className: classNames(styles['timeline-step__progress__bottom-line'], styles[stateColorMap[!['default', 'dashed'].includes(nextState !== null && nextState !== void 0 ? nextState : '') &&
                    !['default', 'dashed'].includes(state)
                    ? 'done'
                    : 'default']], 'dashed' === nextState && styles['timeline-step__progress__bottom-line-dashed']), children: nextState === 'dashed' && (_jsxs(_Fragment, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] })) }))] }));
};
export const TimelineStep = ({ state, title, description, isLastStep, prevState, nextState, imageUrl, imageAction, timelineStepAction, }) => {
    return (_jsxs("div", { className: styles['timeline-step'], children: [_jsx(ProgressStep, { isLastStep: isLastStep, prevState: prevState, nextState: nextState, state: state }), _jsxs("div", { className: styles['timeline-step__content'], children: [_jsxs(ListItem, { heading: title, children: [typeof description === 'string' && _jsx(Paragraph, { children: description }), Array.isArray(description) && description.map((desc) => _jsx(Paragraph, { children: desc }))] }), imageUrl && (_jsx("button", { "aria-label": "show image", type: "button", disabled: !imageAction, className: styles['timeline-step__image-action'], onClick: imageAction, children: _jsx("img", { alt: "image", className: styles['timeline-step__image'], src: imageUrl }) })), timelineStepAction && (_jsx(Button, { onClick: timelineStepAction.action, size: "small", variant: "secondary", text: timelineStepAction.text }))] })] }));
};
