import { useCallback, useEffect, useRef } from 'react';
import { isModalOpen } from '@gordon/web-ui';
export const RFIDInput = ({ enabled, prefix, onScanResult, }) => {
    const input = useRef('');
    const lastCtrlKey = useRef('');
    const parseResult = useCallback((result = '') => result.split(' ').filter((code) => (prefix ? code.startsWith(prefix) : !!code)), [prefix]);
    useEffect(() => {
        if (!enabled) {
            return;
        }
        const checkInput = (e) => {
            if (['Shift', 'Control'].includes(e.key))
                return;
            const isModalVisible = isModalOpen();
            if (isModalVisible) {
                return;
            }
            if (!e.ctrlKey) {
                input.current += e.key;
                lastCtrlKey.current = '';
                return;
            }
            if (e.key === 'j' && lastCtrlKey.current === 'm') {
                lastCtrlKey.current = '';
                // Should only be one but better safe than sorry
                const ids = parseResult(input.current);
                input.current = '';
                if (ids.length === 0) {
                    return;
                }
                return onScanResult === null || onScanResult === void 0 ? void 0 : onScanResult({ scanCode: ids[ids.length - 1], tag: 'rfid' });
            }
            lastCtrlKey.current = e.key;
        };
        input.current = '';
        window.addEventListener('keydown', checkInput);
        // Unsubscribe
        return () => window.removeEventListener('keydown', checkInput);
    }, [enabled]);
    return null;
};
