import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal, ModalColumn, ModalRow, ModalSection, Paragraph } from '@gordon/web-ui';
export default function PreloadDoneModal({ onClose = () => { }, onConfirm = () => { }, scannedItem, }) {
    return (_jsx(Modal, { title: "Pre-load done", size: "small", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", text: "Confirm", onClick: () => {
                        onConfirm();
                        onClose();
                    } })] }), children: _jsxs(ModalSection, { children: [_jsxs(ModalRow, { columns: 2, children: [_jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", children: "Route:" }) }), _jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", strong: true, children: scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.routeName }) })] }), _jsxs(ModalRow, { columns: 2, children: [_jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", children: "Items:" }) }), _jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", strong: true, children: !!(scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.totalItems) && !!(scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanProgress)
                                    ? `${scannedItem.scanProgress}/${scannedItem.totalItems} items pre-loaded`
                                    : '' }) })] })] }) }));
}
