import { useEffect, useState } from 'react';
import { isModalOpen } from '@gordon/web-ui';
export default function useExternalScanner(onScanResult, disabled) {
    const [externalScanCode, setExternalScanCode] = useState('');
    function handleScanInput(e) {
        const isModalVisible = isModalOpen();
        if (isModalVisible)
            return;
        return setExternalScanCode((prevValue) => `${prevValue}${e.key}`);
    }
    function resetState() {
        setExternalScanCode('');
    }
    useEffect(() => {
        if (!externalScanCode)
            return;
        const formattedScanCode = externalScanCode.endsWith('Enter')
            ? externalScanCode.replace('Enter', '')
            : externalScanCode;
        const timeoutId = setTimeout(() => {
            onScanResult({ scanCode: formattedScanCode, tag: 'externalScanner' });
            resetState();
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [externalScanCode]);
    useEffect(() => {
        if (!window || disabled)
            return;
        window.addEventListener('keypress', handleScanInput);
        return () => window.removeEventListener('keypress', handleScanInput);
    }, [window, disabled]);
}
