import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './ScanList.scss';
import ScanListItem from './ScanListItem';
import SectionHeader from '../SectionHeader/SectionHeader';
import { Button } from '@gordon/web-ui';
const RenderScanListItems = ({ scanListItems, showAll, }) => {
    const sortedByStop = [...scanListItems].sort((a, b) => {
        var _a, _b;
        const stopA = (_a = a.stopNumber) !== null && _a !== void 0 ? _a : Number.MAX_SAFE_INTEGER;
        const stopB = (_b = b.stopNumber) !== null && _b !== void 0 ? _b : Number.MAX_SAFE_INTEGER;
        return stopA - stopB;
    });
    const fullySortedItems = sortedByStop.sort((a, b) => {
        const aHasPreLoaded = a.scanHistory.some((scan) => scan.status === 'Pre-loaded');
        const bHasPreLoaded = b.scanHistory.some((scan) => scan.status === 'Pre-loaded');
        return aHasPreLoaded === bHasPreLoaded ? 0 : aHasPreLoaded ? 1 : -1;
    });
    return (_jsx(_Fragment, { children: fullySortedItems.slice(0, showAll ? fullySortedItems.length : 10).map((item) => (_jsx(ScanListItem, { item: item }, `${item.orderId}${item.itemNumber}`))) }));
};
export const ScanList = ({ scanListItems, scanProgress, totalItems, }) => {
    const [showAll, setShowAll] = React.useState(false);
    if (!scanListItems)
        return null;
    return (_jsxs("div", { className: "scan-list", children: [_jsx("div", { className: "scan-list-header", children: _jsx(SectionHeader, { heading: `Scan list (${scanProgress}/${totalItems})` }) }), _jsx(RenderScanListItems, { scanListItems: scanListItems, showAll: showAll }), scanListItems.length > 10 && (_jsx("div", { className: "scan-list-footer", children: _jsx(Button, { size: "small", variant: "secondary", text: showAll ? 'Show less' : `Show ${scanListItems.length - 10} more`, onClick: () => setShowAll(!showAll) }) }))] }));
};
