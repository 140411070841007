import { useEffect, useState } from 'react';
import { useFetchScanResult } from '../requests/scan';
import { useCoordinates } from '../stores/location';
import { useDepots } from '../stores/depot';
export default function useScanner({ state, onSuccessfulScan, onScanError, }) {
    var _a, _b;
    const [scan, setScan] = useState(undefined);
    const { mode, undoNextScan, routeId, printLabelOnScan } = state;
    const isDestinationDepotEnabled = mode === 'arrive' || mode === 'sort';
    const { lat, lng } = useCoordinates();
    const { depot, destinationDepot } = useDepots();
    const { runRequest, data, error, isLoading } = useFetchScanResult((_a = scan === null || scan === void 0 ? void 0 : scan.scanCode) !== null && _a !== void 0 ? _a : '', (_b = scan === null || scan === void 0 ? void 0 : scan.tag) !== null && _b !== void 0 ? _b : '', printLabelOnScan.toString());
    useEffect(() => {
        if (scan) {
            // prevent double scan since undoNextScan is set to false when scanned
            const id = setTimeout(() => setScan(undefined), 1000);
            return () => clearTimeout(id);
        }
    }, [depot, destinationDepot, mode, undoNextScan, printLabelOnScan]);
    useEffect(() => {
        var _a;
        if (!scan || !lat || !lng)
            return;
        if (!depot) {
            return onScanError('No depot selected');
        }
        runRequest({
            depotId: depot._id,
            destinationDepotId: isDestinationDepotEnabled ? destinationDepot === null || destinationDepot === void 0 ? void 0 : destinationDepot._id : undefined,
            routeId: (_a = scan === null || scan === void 0 ? void 0 : scan.routeId) !== null && _a !== void 0 ? _a : routeId,
            mode: mode,
            undo: undoNextScan,
            lat: lat,
            lng: lng,
        });
    }, [scan === null || scan === void 0 ? void 0 : scan.scanCode]);
    function runScanRequest(params) {
        if (!params.scanCode)
            return onScanError('Missing scanCode');
        setScan(params);
    }
    useEffect(() => {
        if (data && scan) {
            onSuccessfulScan({ scannedItem: data, params: scan }, runScanRequest);
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            const timeoutId = setTimeout(() => setScan(undefined), 1000);
            onScanError(error.message);
            return () => clearTimeout(timeoutId);
        }
    }, [error]);
    return {
        scanCode: scan === null || scan === void 0 ? void 0 : scan.scanCode,
        isLoading,
        sendScanRequest: runScanRequest,
        isDestinationDepotEnabled,
    };
}
