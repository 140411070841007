import { useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';
import { DecodeHintType, BarcodeFormat } from '@zxing/library';
import { isModalOpen } from '@gordon/web-ui';
const enabledFormats = [
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_93,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION,
    BarcodeFormat.EAN_13,
    BarcodeFormat.EAN_8,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.AZTEC,
    BarcodeFormat.ITF,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
];
const hints = new Map([
    [DecodeHintType.POSSIBLE_FORMATS, enabledFormats],
    [DecodeHintType.ALLOWED_EAN_EXTENSIONS, true],
]);
const isVideoElement = (element) => !!element && element instanceof HTMLVideoElement;
export default function useZxingScanner(setScanCode) {
    useEffect(() => {
        const barcodeScanner = new BrowserMultiFormatReader(hints);
        let ctrl;
        const videoElement = document.getElementById('wh-app-video');
        if (!isVideoElement(videoElement))
            return;
        barcodeScanner.decodeFromVideoDevice(undefined, videoElement, (result, err, controls) => {
            const isModalVisible = isModalOpen();
            if (isModalVisible) {
                return;
            }
            ctrl = controls;
            const text = result === null || result === void 0 ? void 0 : result.getText();
            if (text) {
                setScanCode(text);
            }
        });
        return () => ctrl === null || ctrl === void 0 ? void 0 : ctrl.stop();
    }, []);
}
