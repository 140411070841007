import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import VideoSection from '../components/VideoSection/VideoSection';
import { Alert, Badge, Button, IconCamera, IconCameraOff, IconGordonCircular, LoadingSpinner, Paragraph, } from '@gordon/web-ui';
import './RegisterCircularBag.scss';
import { useRegisterBag } from '../requests/circular';
import { RFIDInput } from '../components/RfidInput';
import useExternalScanner from '../utils/useExternalScanner';
const successAudio = new Audio('audio/success.mp3');
const successLongAudio = new Audio('audio/success-long.mp3');
const failAudio = new Audio('audio/fail.mp3');
const CircularBag = ({ bag, count }) => {
    var _a;
    const { runRequest, data, isLoading, error } = useRegisterBag();
    useEffect(() => {
        if (bag.rfidCode && !data && !error) {
            runRequest({ qrCode: bag.qrCode, rfidCode: bag.rfidCode });
        }
    }, [bag.rfidCode]);
    useEffect(() => {
        if (error) {
            failAudio.play();
        }
    }, [error]);
    useEffect(() => {
        if (data) {
            successLongAudio.play();
        }
    }, [data]);
    return (_jsxs("div", { className: "bag-box", children: [_jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, children: [_jsxs(Paragraph, { strong: true, children: ["Bag #", count] }), isLoading ? (_jsx(LoadingSpinner, {})) : error ? (_jsx(Badge, { variant: "error", text: error.message === 'Bag already exists' ? 'Bag already exists' : 'Could not register' })) : bag.rfidCode ? (_jsx(Badge, { variant: "success", text: "Added" })) : (_jsx(Badge, { variant: "brand", text: "Scan RFID" }))] }), _jsxs("div", { children: [_jsxs(Paragraph, { children: ["QR: ", bag.qrCode] }), _jsxs(Paragraph, { children: ["RFID: ", (_a = bag.rfidCode) !== null && _a !== void 0 ? _a : 'pending'] })] })] }));
};
export default function RegisterCircularBagPage() {
    const [video, setShowVideo] = useState(false);
    const [step, setStep] = useState('QR');
    const rfidMatches = useRef([]);
    const timeoutRef = useRef(undefined);
    const [bags, setBags] = useState([]);
    const [alert, setAlert] = useState(null);
    const updateBags = useCallback((type, code) => {
        setBags((prev) => {
            if (type === 'RFID') {
                const [lastBag, ...rest] = prev;
                return [Object.assign(Object.assign({}, lastBag), { rfidCode: code }), ...rest];
            }
            return [{ qrCode: code, rfidCode: undefined }, ...prev];
        });
        setStep('NONE');
        // Delay to avoid keyboard conflicts between QR and RFID scanner
        setTimeout(() => setStep(type === 'QR' ? 'RFID' : 'QR'), 500);
        setAlert(null);
    }, []);
    const qrScan = useCallback(({ scanCode }) => {
        if (scanCode.startsWith('CR')) {
            successAudio.play();
            updateBags('QR', scanCode);
        }
        else {
            setAlert({
                heading: 'Invalid QR code',
                supportingText: 'Please make sure you are scanning a circular bag QR code',
            });
        }
    }, []);
    const rfidScan = useCallback((scanCode) => {
        clearTimeout(timeoutRef.current);
        rfidMatches.current.push(scanCode);
        timeoutRef.current = setTimeout(() => {
            const matches = rfidMatches.current;
            rfidMatches.current = [];
            if (matches.length === 1) {
                updateBags('RFID', matches[0]);
            }
            else if (matches.length === 0) {
                setAlert({
                    heading: 'No RFID code found',
                    supportingText: 'Please make sure you are scanning a circular bag RFID code',
                });
            }
            else {
                setAlert({
                    heading: 'Found more than one RFID code',
                    supportingText: 'Please make sure you are scanning only one RFID code',
                });
            }
        }, 500);
    }, []);
    useExternalScanner(qrScan, video || step !== 'QR');
    return (_jsxs("div", { className: "register-bag-wrapper", children: [_jsxs("div", { className: "top-menu", children: [_jsx(IconGordonCircular, { size: "h1" }), _jsx(Button, { iconType: "only", text: "Camera", variant: "secondary", icon: video ? IconCameraOff : IconCamera, onClick: () => setShowVideo((prev) => !prev) })] }), video && (_jsx("div", { children: _jsx(VideoSection, { onScanResult: qrScan }) })), _jsx("div", { className: "scan-banner", children: _jsx(Paragraph, { color: "inverse", size: "large", strong: true, children: step === 'NONE'
                        ? ' '
                        : step === 'QR'
                            ? 'Scan QR to start registration'
                            : 'Scan RFID to complete' }) }), _jsx(RFIDInput, { onScanResult: ({ scanCode }) => {
                    successAudio.play();
                    rfidScan(scanCode);
                }, enabled: step === 'RFID', prefix: "300ED89F335" }), _jsx("div", { className: "bag-list", children: bags.map((bag, index) => (_jsx(CircularBag, { bag: bag, count: bags.length - index }, bags.length - index))) }), alert && (_jsx("div", { className: "alert-banner", children: _jsx(Alert, { variant: "error", heading: alert.heading, supportingText: alert.supportingText, onClose: () => setAlert(null) }) }))] }));
}
