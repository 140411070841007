import { useFetch } from './useFetch';
export function useRegisterBag() {
    return useFetch(`wh/v2/circular-bags`, 'POST');
}
export function useValidateBags() {
    return useFetch(`wh/v2/circular-bags/bulk-validate`, 'POST');
}
export function useUpdateBags() {
    return useFetch(`wh/v2/circular-bags/bulk-action`, 'POST');
}
export function useGetLaundries() {
    return useFetch(`wh/v2/circular-bags/laundries`, 'GET');
}
export function useGetClients() {
    return useFetch(`wh/v2/circular-bags/clients`, 'GET');
}
