import { useModals } from '@gordon/web-ui';
import ChangeDepotModal from '../components/Modals/ChangeDepot/ChangeDepotModal';
import ErrorModal from '../components/Modals/Error/ErrorModal';
import ConfirmPreloadRouteModal from '../components/Modals/ConfirmPreloadRoute/ConfirmPreloadRouteModal';
import PreloadDoneModal from '../components/Modals/PreloadDone/PreloadDoneModal';
import BulkUpdateCircularBagsModal from '../components/Modals/BulkUpdateCircularBags/BulkUpdateCircularBagsModal';
export default function useModal() {
    return useModals({
        changeDepot: ChangeDepotModal,
        changeDestinationDepot: ChangeDepotModal,
        error: ErrorModal,
        confirmPreloadRoute: ConfirmPreloadRouteModal,
        preloadDone: PreloadDoneModal,
        bulkUpdateCircularBags: BulkUpdateCircularBagsModal,
    });
}
