import { useEffect } from 'react';
import { useDepots as useFetchDepots, useNearestDepot } from '../requests/depots';
import { create } from 'zustand';
import { useCoordinates } from './location';
import { useShallow } from 'zustand/react/shallow';
const useDepotStore = create()((set) => ({
    depots: [],
    depot: undefined,
    destinationDepot: undefined,
    setDestinationDepot: (destinationDepot) => set({ destinationDepot }),
    setDepot: (depot) => set({ depot }),
    setDepots: (depots) => set({ depots }),
}));
export function useSetupDepotStore() {
    const { lat, lng } = useCoordinates();
    const { setDepots, setDestinationDepot, setDepot } = useDepotStore(useShallow(({ setDepots, setDestinationDepot, setDepot }) => ({
        setDepots,
        setDestinationDepot,
        setDepot,
    })));
    const { runRequest: getNearestDepot, data: nearestDepotResult, isLoading } = useNearestDepot();
    const { runRequest: getDepots, data: depotsResult } = useFetchDepots();
    useEffect(() => {
        if (!lat || !lng)
            return;
        if (nearestDepotResult || isLoading)
            return;
        getNearestDepot({ lat, lng });
    }, [lat, lng]);
    useEffect(() => {
        getDepots();
    }, []);
    useEffect(() => {
        if (nearestDepotResult) {
            setDepot(nearestDepotResult);
            setDestinationDepot(nearestDepotResult);
        }
    }, [nearestDepotResult]);
    useEffect(() => {
        if (depotsResult) {
            setDepots(depotsResult);
        }
    }, [depotsResult]);
}
export const useDepots = () => useDepotStore(useShallow(({ depots, depot, destinationDepot, setDepot, setDestinationDepot }) => ({
    depots,
    depot,
    destinationDepot,
    setDepot,
    setDestinationDepot,
})));
