import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import './Dashboard.scss';
import dayjs from 'dayjs';
import DashboardTableRow from '../components/DashboardTableRow';
import { useFetch } from '../requests/useFetch';
export const Dashboard = () => {
    const [time, setTime] = useState(dayjs());
    const url = new URL(window.location.href);
    const depotId = url.searchParams.get('id');
    const { runRequest, data } = useFetch(`warehouse-dashboard/routes?id=${depotId}`, 'GET', {
        triggerOnMount: false,
    });
    useEffect(() => {
        if (depotId) {
            runRequest();
        }
    }, []);
    useEffect(() => {
        if (depotId) {
            const interval = setInterval(() => {
                runRequest();
            }, 1000 * 20);
            return () => clearInterval(interval);
        }
    }, [data]);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(dayjs());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (_jsx("div", { className: "dashboard-container", children: data && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "dashboard-head", children: [_jsx("h1", { children: data.depotName }), _jsx("span", { children: time.format('HH:mm:ss') })] }), _jsxs("table", { children: [_jsx("thead", { className: "table-head", children: _jsxs("tr", { className: "table-row", children: [_jsx("th", { style: { width: '17%' }, align: "left", children: "START" }), _jsx("th", { style: { width: '17%' }, align: "left", children: "COMPLETED" }), _jsx("th", { style: { width: '30%' }, align: "left", children: "ROUTE" }), _jsx("th", { style: { width: '10%' }, children: "GATE" }), _jsx("th", { style: { width: '26%' }, align: "left", children: "STATUS" })] }) }), _jsx("tbody", { children: data.routes.map((route) => (_jsx(DashboardTableRow, { route: route }, route.name))) })] })] })) }));
};
