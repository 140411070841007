import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Select, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useCoordinates } from '../../../stores/location';
import { useDepots } from '../../../stores/depot';
import { usePromise } from '@gordon/frontend/usePromise';
import { api } from '../../../requests/api';
const options = [
    { text: 'Returned', value: 'return' },
    { text: 'Sent to wash', value: 'wash' },
    { text: 'Sent to customer', value: 'sent_to_client' },
];
const useListToOptions = (items) => useMemo(() => items === null || items === void 0 ? void 0 : items.map(({ _id, name }) => ({ text: name, value: _id })), [items]);
export default function BulkUpdateCircularBagsModal({ onClose, onSuccess, ids, }) {
    const [status, setStatus] = React.useState(undefined);
    const [laundry, setLaundry] = React.useState(undefined);
    const [client, setClient] = React.useState(undefined);
    const { run, isLoading, isSuccess, isError } = usePromise(api.circular.updateBags);
    const { lat, lng } = useCoordinates();
    const { depot } = useDepots();
    const { data: clients } = usePromise(api.circular.getClients, { triggerOnMount: true });
    const { data: laundries } = usePromise(api.circular.getLaundries, { triggerOnMount: true });
    const laundryOptions = useListToOptions(laundries);
    const clientOptions = useListToOptions(clients);
    useEffect(() => {
        if (clients && clients.length === 1) {
            setClient(clients[0]._id);
        }
    }, [clients]);
    useEffect(() => {
        if (laundries && laundries.length === 1) {
            setLaundry(laundries[0]._id);
        }
    }, [laundries]);
    useEffect(() => {
        var _a;
        if (isSuccess) {
            onSuccess(status === 'return'
                ? 'returned'
                : status === 'wash'
                    ? 'sent to wash'
                    : `sent to customer (${(_a = clients === null || clients === void 0 ? void 0 : clients.find((c) => c._id === client)) === null || _a === void 0 ? void 0 : _a.name})`);
            onClose();
        }
    }, [isSuccess]);
    const requestBody = status === 'return' && depot
        ? {
            type: 'return',
            depotId: depot._id,
        }
        : status === 'wash' && laundry
            ? {
                type: 'wash',
                depotId: laundry,
            }
            : status === 'sent_to_client' && client
                ? {
                    type: 'sent_to_client',
                    clientId: client,
                }
                : undefined;
    return (_jsx(Modal, { title: "Select status", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { text: "Cancel", variant: "secondary", onClick: onClose }), _jsx(Button, { isLoading: isLoading, disabled: !requestBody, text: "Confirm", variant: "primary", onClick: () => {
                        if (requestBody) {
                            run(Object.assign(Object.assign({}, requestBody), { lat: lat, lng: lng, scanType: 'rfid', bagIds: ids }));
                        }
                    } })] }), children: _jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Select, { label: "Status", placeholder: "Select status", options: options, value: status, onSelect: setStatus }) }), status === 'wash' && (_jsx(ModalRow, { children: _jsx(Select, { label: "Laundry", placeholder: "Select laundry", options: laundryOptions, value: laundry, onSelect: setLaundry }) })), status === 'sent_to_client' && (_jsx(ModalRow, { children: _jsx(Select, { disabled: !(clients === null || clients === void 0 ? void 0 : clients.length), label: "Delivery Group", placeholder: "Select delivery group", options: clientOptions, value: client, onSelect: setClient }) })), isError && (_jsx(ModalRow, { children: _jsx(Alert, { heading: "Something went wrong", variant: "error" }) }))] }) }));
}
