import { useCallback, useState } from 'react';
import printLabel from './printLabel';
import _throttle from 'lodash/throttle';
const successAudio = new Audio('audio/success.mp3');
const successLongAudio = new Audio('audio/success-long.mp3');
const failAudio = new Audio('audio/fail.mp3');
export default function useActions({ showModal }) {
    const [state, setState] = useState({
        mode: 'info',
        showVideo: false,
        undoNextScan: false,
        printLabelOnScan: false,
        routeId: undefined, // used for preload
        scannedItem: undefined,
        scanError: undefined,
    });
    const { routeId, mode, printLabelOnScan } = state;
    function togglePrintLabel() {
        return setState((prevState) => (Object.assign(Object.assign({}, prevState), { printLabelOnScan: !prevState.printLabelOnScan })));
    }
    function toggleUndoNextScan() {
        return setState((prevState) => (Object.assign(Object.assign({}, prevState), { undoNextScan: !prevState.undoNextScan })));
    }
    function toggleVideo() {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { showVideo: !prevState.showVideo })));
    }
    const toggleVideoThrottle = useCallback(_throttle(toggleVideo, 1000, {
        leading: true,
        trailing: false,
    }), []);
    function setRouteId(routeId) {
        return setState((prevState) => (Object.assign(Object.assign(Object.assign({}, prevState), { routeId }), (!routeId ? { scannedItem: undefined } : {}))));
    }
    function setMode(mode) {
        if (!mode) {
            return;
        }
        return setState((prevState) => (Object.assign(Object.assign({}, prevState), { mode, scannedItem: undefined, routeId: undefined })));
    }
    function onSuccessfulScan(itemData, sendScanRequest) {
        const { scannedItem, params } = itemData;
        const preloadDone = routeId &&
            mode === 'preload' &&
            (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanProgress) &&
            (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.totalItems) &&
            (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanProgress) === (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.totalItems);
        if (!params.reRunPreload) {
            successAudio.play();
        }
        else if (preloadDone) {
            successLongAudio.play();
        }
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { scannedItem, undoNextScan: false })));
        if (!routeId && mode === 'preload' && !params.reRunPreload) {
            if (!scannedItem.routeId) {
                showModal('error', { message: 'This item has not been routed' });
            }
            else {
                showModal('confirmPreloadRoute', {
                    scannedItem,
                    onConfirm: () => {
                        setState((prevState) => (Object.assign(Object.assign({}, prevState), { routeId: scannedItem.routeId })));
                        sendScanRequest({
                            scanCode: params.scanCode,
                            tag: params.tag,
                            routeId: scannedItem.routeId,
                            reRunPreload: true,
                        });
                    },
                    onCancel: () => {
                        setState((prevState) => (Object.assign(Object.assign({}, prevState), { scannedItem: undefined })));
                    },
                });
            }
        }
        if (preloadDone) {
            showModal('preloadDone', {
                scannedItem,
                onConfirm: () => {
                    setState((prevState) => (Object.assign(Object.assign({}, prevState), { routeId: undefined, scannedItem: undefined })));
                },
            });
        }
        if (printLabelOnScan) {
            try {
                printLabel(scannedItem, showModal);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            }
            catch (err) {
                showModal('error', { message: 'Cannot print label' });
            }
        }
    }
    function onScanError(error) {
        failAudio.play();
        showModal('error', { message: error });
        return setState((prevState) => (Object.assign(Object.assign({}, prevState), { scanError: error })));
    }
    function setScanner(scannerOption) {
        return setState((prevState) => (Object.assign(Object.assign({}, prevState), { scannerOption })));
    }
    return [
        {
            togglePrintLabel,
            toggleUndoNextScan,
            toggleVideo,
            toggleVideoThrottle,
            setMode,
            setRouteId,
            onSuccessfulScan,
            onScanError,
            setScanner,
        },
        state,
    ];
}
